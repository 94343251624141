/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { JSONMap as e } from "../../core/jsonMap.js";
import { JSONSupport as i } from "../../core/JSONSupport.js";
import { clone as r } from "../../core/lang.js";
import { property as o } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as a } from "../../core/accessorSupport/decorators/writer.js";
var c;
const n = new e({
  count: "count",
  sum: "sum",
  min: "min",
  max: "max",
  avg: "avg",
  stddev: "stddev",
  var: "var",
  exceedslimit: "exceedslimit",
  percentile_cont: "percentile-continuous",
  percentile_disc: "percentile-discrete",
  EnvelopeAggregate: "envelope-aggregate",
  CentroidAggregate: "centroid-aggregate",
  ConvexHullAggregate: "convex-hull-aggregate"
});
let p = c = class extends i {
  constructor(t) {
    super(t), this.maxPointCount = void 0, this.maxRecordCount = void 0, this.maxVertexCount = void 0, this.onStatisticField = null, this.outStatisticFieldName = null, this.statisticType = null, this.statisticParameters = null;
  }
  writeStatisticParameters(t, e) {
    "percentile-continuous" !== this.statisticType && "percentile-discrete" !== this.statisticType || (e.statisticParameters = r(t));
  }
  clone() {
    return new c({
      maxPointCount: this.maxPointCount,
      maxRecordCount: this.maxRecordCount,
      maxVertexCount: this.maxVertexCount,
      onStatisticField: this.onStatisticField,
      outStatisticFieldName: this.outStatisticFieldName,
      statisticType: this.statisticType,
      statisticParameters: r(this.statisticParameters)
    });
  }
};
t([o({
  type: Number,
  json: {
    write: !0
  }
})], p.prototype, "maxPointCount", void 0), t([o({
  type: Number,
  json: {
    write: !0
  }
})], p.prototype, "maxRecordCount", void 0), t([o({
  type: Number,
  json: {
    write: !0
  }
})], p.prototype, "maxVertexCount", void 0), t([o({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "onStatisticField", void 0), t([o({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "outStatisticFieldName", void 0), t([o({
  type: String,
  json: {
    read: {
      source: "statisticType",
      reader: n.read
    },
    write: {
      target: "statisticType",
      writer: n.write
    }
  }
})], p.prototype, "statisticType", void 0), t([o({
  type: Object
})], p.prototype, "statisticParameters", void 0), t([a("statisticParameters")], p.prototype, "writeStatisticParameters", null), p = c = t([s("esri.rest.support.StatisticDefinition")], p);
const m = p;
export { m as default };